import React from 'react';
import ScheduleGrid from '../components/schedule'

const Schedule = () => (

    <div className='w-11/12 mx-auto my-5 p-5'>
        <h1 className='text-center'>DevFest Baton Rouge Schedule & Session Information</h1>
       
        <ScheduleGrid />
    </div>
   
);

export default Schedule;