import React from 'react';
import LSULogo from '../assets/sponsors/LSU_Business_StephensonDeptofEntrepreneurshipandInfoSystems_ppl.png';
import MarkLogo from '../assets/sponsors/marklogo.png';
import JetbrainsLogo from '../assets/sponsors/jetbrains_logo.png';
import GoogleLogo from '../assets/sponsors/Google_2015_logo.png';
import AxureLogo from '../assets/sponsors/Axure_logo_400@1x.png';
import OrcaLogo from '../assets/sponsors/OrcaSecurityLogo.jpg';
import OReillyLogo from '../assets/sponsors/OReillyLogo.jpg';
import CGILogo from '../assets/sponsors/CGI_logo_color.png';
import Crimer from '../assets/sponsors/crimer.png';
import RayW from '../assets/sponsors/rw.png';

const SponsorLogos = () => (

    <div className='text-center my-20'>
        <h2>Sponsors</h2>
        <p>Thank you to our sponsors for their generous support.</p>

        <section className='my-20'>
            <h3 className='my-10 font-semibold'>Platinum</h3>
            <div className='flex w-full h-full px-10 justify-center'>
                <img src={GoogleLogo} alt="Google Logo" className="object-contain h-48 w-full"></img>
            </div>
        </section>
        
        <section className='my-20'>
            <h3 className='my-10 font-semibold'>Gold</h3>
                <div className='flex w-full h-full justify-center py-10 px-5'>
                    <a href="https://www.cgi.com/">
                        <img src={CGILogo} alt="CGI Logo" className="object-contain h-48 w-full"></img>
                    </a>
              
                </div>
                <div className='flex w-full h-full justify-center py-10'>
                    <a href="https://www.lsu.edu/business/sdeis/index.php">
                        <img src={LSULogo} alt="LSU SDEIS Logo" className="object-contain h-48 w-full"></img>
                    </a>
                </div>
        </section>
        
        <section className='my-20'>
            <h3 className='my-10 font-semibold'>Silver</h3>
            <div className='flex w-full h-full justify-center'>
                <a href="https://orca.security/">
                    <img src={OrcaLogo} alt="Orca Security Logo" className="object-contain h-36 w-full"></img>
                </a>
            </div>
        </section>
        <section className='my-20'>
            <h3 className='my-10 font-semibold'>Bronze</h3>    
            <div className='flex w-full h-full justify-center'>
                <a href="http://mark.institute/">
                    <img src={MarkLogo} alt="Mark Logo" className="object-contain h-36 w-full"></img>
                </a>
            </div>
        </section>
        <section className='my-20'>
            <h3 className='my-10 font-semibold'>Startup</h3>    
            <div className='flex w-full h-full justify-center'>
                <a href="https://www.crimer.com/">
                    <img src={Crimer} alt="Crimer Logo" className="object-contain h-18 w-full"></img>
                </a>
            </div>
        </section>

        <section>
            <h3 className='my-10 font-semibold'>Supporters</h3>
            <div className='w-3/4 grid grid-cols-1 md:grid-cols-4 mx-auto'>
                <div className='m-3'>
                    <a href="https://www.axure.com/">
                    <img src={AxureLogo} alt="Axure Logo" className="object-contain h-24 w-full"></img>
                    </a>
                </div>
                <div className='m-3'>
                    <a href="https://www.jetbrains.com/">
                    <img src={JetbrainsLogo} alt="Jetbrains logo" className="object-contain h-24 w-full"></img>
                    </a>
                </div> 
                <div className='m-3'>
                    <a href="https://www.oreilly.com/">
                    <img src={OReillyLogo} alt="O'Reilly Logo" className="object-contain h-24 w-full"></img>
                    </a>
                </div> 
                <div className='m-3'>
                    <a href="https://www.raywenderlich.com/">
                    <img src={RayW} alt="RayWenderlich Logo" className="object-contain h-24 w-full"></img>
                    </a>
                </div> 
            </div> 
        </section>
       

    </div>
);

export default SponsorLogos;