import React, {useEffect,useState } from 'react';

const ScheduleGrid = () => {

    const [slot, setSlot] = useState([])

    const fetchData = () => {
       
        const url = ['https://sessionize.com/api/v2/axcs5701/view/GridSmart']
        Promise.all(fetch(url)
        .then(response => response.json())
        .then(data => {
            setSlot(data[0].timeSlots)
        }))
    
    }
    useEffect(() => {
        fetchData()
      }, [])

    const registration = slot[0] //REGISTRATION
    const sessionOne = slot[1] //SESSION 1
    const breakOne = slot[2] //BREAK
    const sessionTwo = slot[3] //SESSION 2
    const breakTwo = slot[4] //BREAK
    const lunch = slot[5] //LUNCH
    const sessionThree = slot[6] // SESSION 3
    const breakThree = slot[7] // BREAK
    const sessionFour = slot[8] // SESSION 4
    const breakFour = slot[9] // BREAK
    const sessionFive = slot[10] // SESSION 5
    const breakFive = slot[11] // BREAK
    const closing = slot[12] // BREAK


    return(
     <>
       <div className='grid place-items-center my-10  mx-auto'>
            <div className="border-2 w-full rounded-lg border-LightBlue p-5 my-5 bg-LightBlue">
                <h2 className="text-sm text-Blue500 my-4">Saturday, October 29 @ 9:00 AM</h2>
                   {registration ? ( registration.rooms.map(room => (
                        <div className='flex flex-wrap items-center'>
                            <h3 className="text-lg font-bold pr-4">{room.session.title}</h3>
                            <span className="bg-white px-4 py-2 my-3 rounded-full text-sm font-semibold">{room.name}</span>
                        </div>
                   ))
                    
                        ) : ""}
            </div>
            <div className="">
                
                {sessionOne ? ( sessionOne.rooms.map(room => 
                        (<div className="border-2 w-full rounded-lg p-5 my-5 border-LightBlue">
                            <h2 className="text-base text-Blue500">Saturday, October 29 @ 10:00 AM</h2>
                            <div className="flex flex-wrap items-center">
                                <h3 className="text-lg font-bold inline pr-4">{room.session.title}</h3>
                                <span className="bg-LightBlue px-4 py-2 my-3 rounded-full text-sm font-semibold">{room.name}</span>
                            </div>
                            {room.session.speakers.map(speaker => (<h4 className="text-base mb-4">{speaker.name}</h4>))}
                           
                            <p>{room.session.description}</p>
                            
                        </div>)
                    )) : ""
               }
   
            </div>
            <div className="border-2 w-full rounded-lg border-LightBlue p-5 my-5 bg-LightBlue">
                <h2 className="text-sm text-Blue500 my-4">Saturday, October 29 @ 10:50 AM</h2>
                   {breakOne ? ( breakOne.rooms.map(room => (
                        <div className='flex flex-wrap items-center'>
                            <h3 className="text-lg font-bold pr-4">{room.session.title}</h3>
                            
                        </div>
                   ))
                    
                        ) : ""}
            </div>
            <div className="">
                {sessionTwo? (sessionTwo.rooms.map(room => 
                        (<div className="border-2 w-full rounded-lg p-5 my-5 border-LightBlue">
                            <h2 className="text-base text-Blue500">Saturday, October 29 @ 11:00 AM</h2>
                            <div className="flex flex-wrap items-center">
                                <h3 className="text-lg font-bold inline pr-4">{room.session.title}</h3>
                                <span className="bg-LightBlue px-4 py-2 my-3 rounded-full text-sm font-semibold">{room.name}</span>
                            </div>
                            
                            {room.session.speakers.map(speaker => (<h4 className="text-base mb-4">{speaker.name}</h4>))}
                           
                            <p>{room.session.description}</p>
                        </div>)
                    )) : "" }
   
            </div>
            <div className="border-2 w-full rounded-lg border-LightBlue p-5 my-5 bg-LightBlue">
                <h2 className="text-sm text-Blue500 my-4">Saturday, October 29 @ 11:50 AM</h2>
                   {breakTwo ? ( breakTwo.rooms.map(room => (
                        <div className='flex flex-wrap items-center'>
                            <h3 className="text-lg font-bold pr-4">{room.session.title}</h3>
                
                        </div>
                   ))
                    
                        ) : ""}
            </div>
            <div className="border-2 w-full rounded-lg border-LightBlue p-5 my-5 bg-LightBlue">
                <h2 className="text-sm text-Blue500">Saturday, October 29 @ 12:00 PM</h2>
                   {lunch ? ( lunch.rooms.map(room => (
                        <div className='flex flex-wrap items-center'>
                            <h3 className="text-lg font-bold pr-4">{room.session.title}</h3>
                            <span className="bg-white px-4 py-2 my-3 rounded-full text-sm font-semibold">{room.name}</span>
                        </div>
                   ))
                    
                        ) : ""}
            </div>
        
            <div>
                {sessionThree ? (sessionThree.rooms.map(room => 
                 
                        (<div className="border-2 w-full rounded-lg p-5 my-5 border-LightBlue">
                            <h2 className="text-base text-Blue500">Saturday, October 29 @ 1:00 PM</h2>
                            <div className="flex flex-wrap items-center">
                                <h3 className="text-lg font-bold inline pr-4">{room.session.title}</h3>
                                <span className="bg-LightBlue px-4 py-2 my-3 rounded-full text-sm font-semibold">{room.name}</span>
                            </div>
                            {room.session.speakers.map(speaker => (<h4 className="text-base mb-4">{speaker.name}</h4>))}
                            <p>{room.session.description}</p>
                        </div>)
                    )) : ""}
   
            </div>
            <div className="border-2 w-full rounded-lg border-LightBlue p-5 my-5 bg-LightBlue">
                <h2 className="text-sm text-Blue500 my-4">Saturday, October 29 @ 1:50 PM</h2>
                   {breakThree ? ( breakThree.rooms.map(room => (
                        <div className='flex flex-wrap items-center'>
                            <h3 className="text-lg font-bold pr-4">{room.session.title}</h3>
                            
                        </div>
                   ))
                    
                        ) : ""}
            </div>
            <div>
                {sessionFour ? (sessionFour.rooms.map(room => 
                    
                    (<div className="border-2 w-full rounded-lg p-5 my-5 border-LightBlue">
                        <h2 className="text-base text-Blue500">Saturday, October 29 @ 2:00 PM</h2>
                        <div className="flex flex-wrap items-center">
                                <h3 className="text-lg font-bold inline pr-4">{room.session.title}</h3>
                                <span className="bg-LightBlue px-4 py-2 my-3 rounded-full text-sm font-semibold">{room.name}</span>
                        </div>
                        {room.session.speakers.map(speaker => (<h4 className="text-base mb-4">{speaker.name}</h4>))}
                        <p>{room.session.description}</p>
                    </div>)
                )) : ""}
   
            </div>
            <div className="border-2 w-full rounded-lg border-LightBlue p-5 my-5 bg-LightBlue">
                <h2 className="text-sm text-Blue500 my-4">Saturday, October 29 @ 2:50 PM</h2>
                   {breakFour ? ( breakFour.rooms.map(room => (
                        <div className='flex flex-wrap items-center'>
                            <h3 className="text-lg font-bold pr-4">{room.session.title}</h3>
                            
                        </div>
                   ))
                    
                        ) : ""}
            </div>
            <div>
            {sessionFive ? (sessionFive.rooms.map(room => 
                    
                    (<div className="border-2 w-full rounded-lg p-5 my-5 border-LightBlue">
                        <h2 className="text-base text-Blue500">Saturday, October 29 @ 3:00 PM</h2>
                        <div className="flex flex-wrap items-center">
                            <h3 className="text-lg font-bold inline pr-4">{room.session.title}</h3>
                            <span className="bg-LightBlue px-4 py-2 my-3 rounded-full text-sm font-semibold">{room.name}</span>
                        </div>
                        {room.session.speakers.map(speaker => (<h4 className="text-base mb-4">{speaker.name}</h4>))}
                        <p>{room.session.description}</p>
                    </div>)
                )) : ""}
   
            </div>
            <div className="border-2 w-full rounded-lg border-LightBlue p-5 my-5 bg-LightBlue">
                <h2 className="text-sm text-Blue500 my-4">Saturday, October 29 @ 3:50 PM</h2>
                   {breakFive ? ( breakFive.rooms.map(room => (
                        <div className='flex flex-wrap items-center'>
                            <h3 className="text-lg font-bold pr-4">{room.session.title}</h3>
                            
                        </div>
                   ))
                    
                        ) : ""}
            </div>
            <div className="border-2 w-full rounded-lg border-LightBlue p-5 my-5 bg-LightBlue">
                <h2 className="text-sm text-Blue500">Saturday, October 29 @ 4:00 PM</h2>
                   {closing ? ( closing.rooms.map(room => (
                        <div className='flex flex-wrap items-center'>
                            <h3 className="text-lg font-bold pr-4">{room.session.title}</h3>
                            <span className="bg-white px-4 py-2 my-3 rounded-full text-sm font-semibold">{room.name}</span>
                        </div>
                   ))
                    
                        ) : ""}
            </div>
        </div> 
     </>
            
    )
}

export default ScheduleGrid
