import React from 'react';

const Volunteer = () => (

    <div>
        <div className='text-center my-10 mx-10'>
        <h1>Volunteer at DevFest Baton Rouge</h1>
        <p>Calling all volunteers! GDG Baton Rouge needs your help to make DevFest a success! Sign up today to volunteer at DevFest!</p>
        <ol>
            <li className="py-2">Sign up for a volunteer shift here: <a className='hover:text-Blue500 underline' href="https://signup.com/go/kYvytPY">https://signup.com/go/kYvytPY</a></li>
            <li className="py-2">RSVP and attend our virtual volunteer meeting on October 15 @ 11am CST:<br></br><a className='hover:text-Blue500 underline' href="https://gdg.community.dev/events/details/google-gdg-baton-rouge-presents-volunteer-meeting-for-devfest-volunteers/">https://gdg.community.dev/events/details/google-gdg-baton-rouge-presents-volunteer-meeting-for-devfest-volunteers/</a></li>
        </ol>

        <p>All volunteers will have full access to speaker sessions, lunch and receive an official DevFest t-shirt. For questions about volunteering, email <a className='hover:text-Blue500 underline' href="mailto:gdg.batonrouge@gmail.com">gdg.batonrouge@gmail.com</a></p>
        
        </div>
    </div>
    
);

export default Volunteer;
