import React from 'react';
import logo from '../assets/DevFest-Baton-Rouge-Logo.png';
import HamburgerMenu from './hamburger.js';
import Navbar from './navbar.js';

const Header = () => (
 
    <header className="bg-Grey900 py-1 top-0 sticky z-10">
    
        <nav className='flex justify-between items-center w-11/12 mx-auto py-3'>
            <div className='w-64'>
                <a href="https://gdg.community.dev/events/details/google-gdg-baton-rouge-presents-devfest-baton-rouge-2022/">
                    <img src={logo} className=" h-13 sm:h-20 p-4 sm:p-0" alt="DevFest Baton Rouge Logo" />
                </a>
            </div>
            <div>
                <Navbar />
            </div>
            <div className='hidden lg:block'>

                <button className='text-white bg-Red500 rounded-full text-md px-4 py-3 my-4 place-content-center w-36 mx-auto text-md'>
                    <a href="https://gdg.community.dev/events/details/google-gdg-baton-rouge-presents-devfest-baton-rouge-2022/" className='hover:text-white'>Register</a> 
                </button>
            </div>
            <div className=''>
                <HamburgerMenu />
            </div>
        </nav>
    </header>

)

export default Header;

