import React from 'react';

const LocationSection = () => (

    <section className='my-20'>
        <h2 className='text-center'>Venue</h2>
        <div className='w-11/12 sm:my-5 mx-auto grid grid-cols-1 lg:grid-cols-2'>
            <div className="mx-auto px-4">
                <a href="https://www.lsu.edu/business/about/bec.php"><img src="https://www.lsu.edu/business/images/news-images/2022-news-images/bec-aerial-thumbnail.jpg" className='rounded-2xl overflow-hidden' alt='LSU College of Business'></img>
                </a>
            </div>
            <div className="mx-auto px-4 text-center">
                <p className='text-xl lg:text-left lg:pt-0'>We're excited to announce that DevFest Baton Rouge will take place at the <span className="font-semibold">Business Education Complex at Louisiana State University.</span></p>
                
                <a href="https://goo.gl/maps/F4jd31TPjhq39dGX9" className='hover:text-Blue500 lg:text-left'>
                    <p className='text-lg'>E. J. Ourso College of Business<br />
                    4000 Business Education Complex<br />
                    501 South Quad Drive<br />
                    Baton Rouge, LA 70803
                    </p>
                </a>
                 <p className='text-xl lg:text-left'>Special thanks to the <a href="https://www.lsu.edu/business/sdeis/index.php" className='font-semibold hover:text-Blue500'>Stephenson Department of Entrepreneurship & Information Systems</a> at LSU for sponsoring DevFest Baton Rouge on campus.</p>
            
            </div>
            
        </div>
        
    </section>
);

export default LocationSection;