import React from 'react';

const FAQ = () => (


<div className='w-11/12 mx-auto my-5 p-5'>
    <h1 className='text-center my-5'>Frequently Asked Questions about DevFest Baton Rouge</h1>

    <div className='px-10'>
        <h3 className="font-semibold">Can I still register for DevFest Baton Rouge?</h3>
        <p>Our early bird and standard DevFest tickets (include lunch ticket and swag gift) are <b>sold out</b>.</p>

        <p>To give more people access to the educational parts of DevFest, we've released <b>DevFest Sessions-Only tickets</b>. These tickets give access to speaker sessions, but do not include a lunch ticket or swag gift.</p>

        <h3 className="font-semibold">What is the schedule for DevFest Baton Rouge?</h3>
        <p>A detailed schedule is posted on the <a href="https://www.devfestbr.com/schedule">Schedule</a> page of our website.</p>

        <h3 className="font-semibold">Can I register for a specific speaker session?</h3>
        <p>Seating at speaker sessions are first come, first served. We will have 3 concurrent speaker tracks, so while one session might be full, you could find space in another session.</p>

        <h3 className="font-semibold">Where will DevFest take place?</h3>
        <p>DevFest will take place at the Business Education Complex at LSU. Registration and lunch will take place in the Rotunda Commons and Dining Area, while speaker sessions will take place in BEC Classrooms on the South side (1125, 1220, and 1225).  </p>
    
        <h3 className="font-semibold">Will lunch be provided?</h3>
        <p>A lunch ticket will be provided at registration to Early Bird and Standard DevFest ticket holders. Coffee and water will also be available. If you have an Early Bird or Standard ticket and have any dietary needs, please let us know before October 18, 2022 by completing the following form: <a className='hover:text-Blue500 underline' href="https://forms.gle/XT55y44DsLciVHAC7">https://forms.gle/XT55y44DsLciVHAC7</a>.</p>

        <h3 className="font-semibold">How can my company become a sponsor?</h3>
        <p>We have several levels of sponsorship available. Email us at <a  className='hover:text-Blue500 underline' href="mailto:gdg.batonrouge@gmail.com">gdg.batonrouge@gmail.com</a> to become a sponsor.</p>

        <h3 className="font-semibold">What are community tables?</h3>
        <p>We are offering a limited number of tables at the event for community organizations and local startups to showcase their organization and network with our attendees. Complete this form to apply: <a className='hover:text-Blue500 underline' href="https://forms.gle/bWqZBNVpm3SukdPT8">https://forms.gle/bWqZBNVpm3SukdPT8</a>.</p>

        <h3 className="font-semibold">Will there be WiFi?</h3>
        <p>We will provide a guest wifi login and password at registration.</p>
    
        <h3 className="font-semibold">How can I stay up to date about DevFest Baton Rouge?</h3>
        <p>We'll be sending important information via email. You can also follow GDG Baton Rouge on <a className='hover:text-Blue500 underline' href="https://www.twitter.com/GDG_BatonRouge">Twitter</a> and <a className='hover:text-Blue500 underline' href="https://www.linkedin.com/company/google-developer-groups-baton-rouge/">LinkedIn</a> for announcements.</p>


        <h3 className="font-semibold">Can I volunteer at DevFest Baton Rouge?</h3>
        <p>Yes! GDG Baton Rouge relies on volunteers to make this a great event! Sign up for a volunteer shift here: <a className='hover:text-Blue500 underline' href="https://signup.com/go/kYvytPY">https://signup.com/go/kYvytPY</a>. We'll also have a volunteer meeting on October 15 - register here: <a className='hover:text-Blue500 underline' href="https://gdg.community.dev/events/details/google-gdg-baton-rouge-presents-volunteer-meeting-for-devfest-volunteers/">https://gdg.community.dev/events/details/google-gdg-baton-rouge-presents-volunteer-meeting-for-devfest-volunteers/</a>.</p>
         
         <p>All volunteers will have full access to speaker sessions, lunch and receive an official DevFest t-shirt. For questions about volunteering, email <a className='hover:text-Blue500 underline' href="mailto:gdg.batonrouge@gmail.com">gdg.batonrouge@gmail.com</a></p>

        <h3 className="font-semibold">Will sessions be recorded?</h3>
        <p>Unfortunately, we will not be able to record or stream this event.</p>

        <h3 className="font-semibold">Where can I park?</h3>
        <p>There are parking lots immediately adjacent to and across the street from the Business Education Complex. For more information, explore <a className='hover:text-Blue500 underline' href="https://map.concept3d.com/?id=743#!m/273927?ce/9488">LSU's interactive map</a>.</p>
    </div>
    
</div>
   
);

export default FAQ;