import React from 'react';

const CommunityTable = () => (

    <section className='mb-20 mt-0 bg-Yellow500 grid'>
        <h2 className='text-center mt-0 py-4'>Community Tables</h2>
        <p className='text-center w-9/12 mx-auto'>We are offering a limited number of community table spaces at DevFest Baton Rouge. To learn more or sign up, click below. </p>
        <button className='text-white bg-Red500  rounded-full text-md px-6 py-3 my-4 place-content-center w-48 mx-auto text-md'><a href='https://docs.google.com/forms/d/e/1FAIpQLSeKYG-mpBmT63ac8qBow5IMX_E-y6Co_5Sej29Na22kbcjUbw/viewform' className='hover:text-white'>Sign Up</a></button> 
        
    </section>
);

export default CommunityTable;