import React from 'react';
import LocationSection from '../components/location.js';
import DevFestRooms from '../assets/DevFest-BEC-Map.png';

const Location = () => (
        <>
        <LocationSection/>
        <div className="mx-auto px-5">
                <figcaption className='text-center py-5 font-semibold'>DevFest will take place in the Rotunda Commons & Dining Area, and BEC Classrooms 1125, 1220, & 1225.</figcaption>
                <img src={DevFestRooms} alt="LSU College of Business Room Map"></img>
                
        </div>
        </>
 
);

export default Location;